import React, { useEffect, useRef } from "react";
import OktaSignIn from "@okta/okta-signin-widget";
import "../../../node_modules/@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import { useNavigate } from "react-router-dom";
import { keyConstants } from "../../constants";
import { sessionManager } from "../../managers/sessionManager";

const OktaSignInWidget = ({ onSuccess, onError }) => {
  const widgetRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const widget = new OktaSignIn({
      clientId: process.env.REACT_APP_CLIENTID,
      redirectUri: `${window.location.protocol}//${window.location.host}/claim-list`,
      issuer: `${process.env.REACT_APP_BASEURL_OKTA_APP}/oauth2/${process.env.REACT_APP_OKTA_ISSUER_ID}`,
      scopes: keyConstants.SCOPES,
      idps: [
        {
          text: "Safeguard",
          id: process.env.REACT_APP_IDPS_ID
        }
      ]
    });

    widget.renderEl(
      { el: widgetRef.current },
      (res) => {
        if (res.status === "SUCCESS") {
          const { tokens } = res;
          const { accessToken } = tokens;

          sessionManager.setDataInCookies(
            accessToken?.accessToken,
            keyConstants.TOKEN,
            accessToken.expiresAt
          );
          onSuccess(res);
          navigate("/claim-list");
        } else {
          onError(new Error("Unexpected status from Okta Sign-In Widget"));
        }
      },
      onError
    );
    return () => widget.remove();
    //eslint-disable-next-line
  }, []);

  return <div ref={widgetRef} />;
};

export default OktaSignInWidget;
