import React, { useEffect, useState } from "react";
import FeedbackPopup from "../../common/components/popup/feedbackPopup";
import { tireComponent } from "../../constants";
import CheckMarkpopup from "./checkMarkPopup";

const initialListData = [
  {
    componentName: "VIN",
    claimData: "",
    systemData: "",
    result: "",
    feedback: ""
  },
  {
    componentName: "Commercial Usage Allowed",
    claimData: "",
    systemData: "",
    result: "",
    feedback: ""
  },
  {
    componentName: "Customer Last Name",
    claimData: "",
    systemData: "",
    result: "",
    feedback: ""
  },
  {
    componentName: "Customer Address",
    claimData: "",
    systemData: "",
    result: "",
    feedback: ""
  },
  {
    componentName: "Odometer Reading",
    claimData: "",
    systemData: "",
    result: "",
    feedback: ""
  },
  {
    componentName: "Is the difference is <=50 ",
    claimData: "",
    systemData: "",
    result: "",
    feedback: ""
  }
];
const ComparisonList = ({
  imagesData,
  setImagePopup,
  openReasonPopup,
  setElementData,
  elementData,
  handleFeedback,
  setOpenReasonPopup,
  data,
  handleUpdateFeedback,
  setQuestions,
  questions,
  setCheckData,
  checkData
}) => {
  const [hoverId, setHoverId] = useState(null);
  const [comparisonData, setComparisonData] = useState([]);
  const [componentData, setComponentData] = useState(initialListData);
  let uniqueIdCounter = 1;
  const dataMapping = {
    vin: 0,
    commercialUsageAllowed: 1,
    customerLastName: 2,
    customerAddress: 3,
    odoMeterReading: 4,
    odoMeterDifference: 5
  };

  const mapVehicleDataToTbody = (vehicleData, customerData, feedbackData) => {
    const combinedData = { ...vehicleData, ...customerData };

    return initialListData.map((item, index) => {
      const dataKey = Object.keys(dataMapping).find(
        (key) => dataMapping[key] === index
      );
      const vehicleItem = combinedData[dataKey] || {};
      const matchingFeedback = feedbackData?.elementsFeedback?.find(
        (feedback) =>
          feedback.elementName.toLowerCase() ===
          item.componentName.toLowerCase()
      );

      return {
        _id: uniqueIdCounter++,
        componentName: item.componentName,
        claimData: vehicleItem.claimData || item.claimData || "-",
        systemResults: vehicleItem.systemData || item.systemData || "-",
        result: vehicleItem.result || item.result,
        feedback: matchingFeedback ? matchingFeedback : item.feedback
      };
    });
  };

  const mapComponentsToTableData = (components, feedbackData) => {
    return components?.map((component) => {
      const claimDataApprove = component?.sgComponentStatus;

      const isSystemResultApproved =
        component?.componentTypeLocation?.componentStatus;

      const thead = [
        {
          name: component.componentName
        },
        {
          name: claimDataApprove
        },
        {
          name: isSystemResultApproved,
          componentName: component.componentName,
          componentId: component?._id,
          moreSpecification:
            component?.componentTypeLocation?.componentTypeData
              ?.mandatoryChecks,
          isMoreDetailsAvaialble: true,
          reason: component?.componentTypeLocation?.componentDescription,
          feedback: feedbackData?.checks
        },
        {
          name:
            isSystemResultApproved === claimDataApprove
              ? "Matched"
              : "Mismatched",
          status: true
        }
      ];

      const tbody = [];
      const damageDescription =
        component.componentTypeLocation?.componentTypeData
          ?.componentDamageDescription;

      if (damageDescription) {
        Object.keys(damageDescription).forEach((damageType) => {
          const damage = damageDescription[damageType];
          if (damage.isDamageIdentified) {
            setQuestions((prev) => ({
              ...prev,
              [component.componentName]: damage?.componentAdditionalQuestions
            }));
            const matchingFeedback = feedbackData?.elementsFeedback?.find(
              (feedback) =>
                feedback.elementName ===
                "Tire Damage Description" + " " + component.componentName
            );
            tbody.push({
              _id: uniqueIdCounter++,
              element: "Tire Damage Description",
              componentName: component.componentName,
              claimData: component.tireDamageDescription || "-",
              systemResults: `${component.tireDamageDescription || "-"} ${
                damage?.isDamageIdentified === "Yes"
                  ? "identified"
                  : "not identified"
              }`,
              result:
                damage?.isDamageIdentified === "Yes" ? "Matched" : "Mismatched",
              feedback: matchingFeedback ? matchingFeedback : ""
            });
          }
        });
      }
      const matchingFeedback = feedbackData?.elementsFeedback?.find(
        (feedback) =>
          feedback.elementName ===
          "Corrective Action" + " " + component.componentName
      );
      tbody.push({
        _id: uniqueIdCounter++,
        element: "Corrective Action",
        componentName: component.componentName,
        claimData: component.componentTypeLocation?.claimDataAction || "-",
        systemResults: component.componentTypeLocation?.componentAction || "-",
        result:
          component.componentTypeLocation?.claimDataAction ===
          component.componentTypeLocation?.componentAction
            ? "Matched"
            : "Mismatched",
        feedback: matchingFeedback ? matchingFeedback : ""
      });

      return { thead, tbody };
    });
  };

  useEffect(() => {
    let mappedData = [];

    if (data?.vehicleData) {
      mappedData = [
        ...mappedData,
        ...mapVehicleDataToTbody(
          data?.vehicleData,
          data.customer,
          data.feedback || []
        )
      ];
    }

    if (data?.components?.length > 0 && data?.components) {
      const tableData = mapComponentsToTableData(
        data.components,
        data.feedback || []
      );
      setComparisonData(tableData);
    }

    if (mappedData?.length > 0) setComponentData(mappedData);
    //eslint-disable-next-line
  }, [data]);

  const claimData = imagesData?.filter((item) => item.type !== "damage");

  return (
    <div>
      <div>
        <h1 className="font-InterBold text-ft20-28 text-black-10">
          Comparison
        </h1>
      </div>
      <div className="mt-4 w-full rounded-[10px] bg-white">
        <table className="w-full">
          <thead>
            <tr className="font-InterBold text-ft15-18 text-black-10">
              <td className="py-3.5 pl-3.5"></td>
              <td className="border-l border-grey-60 py-3.5 pl-3.5">
                Claim Data
              </td>
              <td className="border-l border-grey-60 py-3.5 pl-3.5">
                System Results
              </td>
              <td className="border-l border-grey-60 py-3.5 pl-3.5">Result</td>
            </tr>
          </thead>

          <ComparisonBody
            openReasonPopup={openReasonPopup}
            setImagePopup={setImagePopup}
            setOpenReasonPopup={setOpenReasonPopup}
            data={componentData}
            hoverId={hoverId}
            imagesData={claimData}
            setHoverId={setHoverId}
            setElementData={setElementData}
            elementData={elementData}
            handleFeedback={handleFeedback}
            handleUpdateFeedback={handleUpdateFeedback}
            feedback={data?.feedback}
          />
        </table>
        {comparisonData.map((item, idx) => (
          <div key={idx}>
            <ComponentTable
              openReasonPopup={openReasonPopup}
              setImagePopup={setImagePopup}
              setOpenReasonPopup={setOpenReasonPopup}
              tbody={item.tbody}
              thead={item.thead}
              hoverId={hoverId}
              imagesData={imagesData}
              setHoverId={setHoverId}
              setElementData={setElementData}
              elementData={elementData}
              handleFeedback={handleFeedback}
              handleUpdateFeedback={handleUpdateFeedback}
              feedback={data?.feedback}
              questions={questions}
              checkData={checkData}
              setCheckData={setCheckData}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ComparisonList;

const ComparisonBody = ({
  data,
  setImagePopup,
  setOpenReasonPopup,
  openReasonPopup,
  setHoverId,
  hoverId,
  imagesData,
  setElementData,
  elementData,
  handleFeedback,
  handleUpdateFeedback,
  feedback
}) => {
  return (
    <tbody>
      {data?.map((item, idx) => (
        <tr key={idx} className="border-t border-grey-30">
          <td className="w-25per py-3.5 pl-3.5 font-InterRegular text-ft10-18.15 text-grey-50">
            <div>{item.componentName}</div>
          </td>
          <td className="w-30per border-l border-grey-60 pl-3.5 font-InterRegular text-ft10-18.15 text-black-10">
            <div>
              {item.claimData?.toLowerCase() === "n"
                ? "No"
                : item?.claimData?.toLowerCase() === "y"
                  ? "Yes"
                  : item.claimData}
            </div>
          </td>
          <td
            onMouseEnter={() => {
              if (!openReasonPopup) setHoverId(item._id);
            }}
            onMouseLeave={() => {
              if (!openReasonPopup) setHoverId(null);
            }}
            className="w-30per border-l border-grey-60 px-3.5 font-InterRegular text-ft10-18.15 text-black-10"
          >
            <div className="flex items-center justify-between">
              <span>
                {item.systemResults?.toLowerCase() === "n"
                  ? "No"
                  : item.systemResults?.toLowerCase() === "y"
                    ? "Yes"
                    : item.systemResults}
              </span>
              {item?.feedback && (
                <div className="relative flex gap-3">
                  {hoverId === item._id && item?.feedback && (
                    <img
                      onClick={() =>
                        setImagePopup((prev) => ({
                          ...prev,
                          popup: true,
                          allImages: imagesData,
                          idx: 0
                        }))
                      }
                      src="/images/image-logo.svg"
                      alt=""
                      className="cursor-pointer"
                    />
                  )}
                  <img
                    onClick={() => {
                      setOpenReasonPopup(item._id);
                      setElementData((prev) => ({
                        ...prev,
                        elementName: item?.feedback?.elementName,
                        systemResult: item?.feedback?.systemResults,
                        claimData: item?.feedback?.claimData,
                        feedback: item.feedback.feedback,
                        comment: item?.feedback?.comment
                      }));
                    }}
                    src="/images/red-thumbs-down.svg"
                    alt=""
                    className="cursor-pointer"
                  />
                  {openReasonPopup === item._id && (
                    <FeedbackPopup
                      setState={() => setOpenReasonPopup(null)}
                      elementData={elementData}
                      setElementData={setElementData}
                      handleFeedback={handleFeedback}
                      handleUpdateFeedback={handleUpdateFeedback}
                      edit={true}
                    />
                  )}
                </div>
              )}
              {hoverId === item._id && !item?.feedback && (
                <div className="relative flex gap-3">
                  <img
                    onClick={() =>
                      setImagePopup((prev) => ({
                        ...prev,
                        popup: true,
                        allImages: imagesData,
                        idx: 0
                      }))
                    }
                    src="/images/image-logo.svg"
                    alt=""
                    className="cursor-pointer"
                  />
                  <img
                    onClick={() => {
                      setOpenReasonPopup(item._id);
                      setElementData((prev) => ({
                        ...prev,
                        elementName: item?.componentName,
                        systemResult: item.systemResults,
                        claimData: item.claimData
                      }));
                    }}
                    src="/images/dislike-black.svg"
                    alt=""
                    className="cursor-pointer"
                  />
                  {openReasonPopup === item._id && (
                    <FeedbackPopup
                      setState={() => setOpenReasonPopup(null)}
                      elementData={elementData}
                      setElementData={setElementData}
                      handleFeedback={handleFeedback}
                      handleUpdateFeedback={handleUpdateFeedback}
                      callUpdate={feedback ? true : false}
                    />
                  )}
                </div>
              )}
            </div>
          </td>
          <td className="w-15per border-l border-grey-60 p-3.5">
            <div
              className={`${
                item.result === "Mismatched" ? " text-red-10" : " text-green-10"
              } rounded-full font-InterMedium text-ft15-18`}
            >
              {item.result || "-"}
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

const ComponentTable = ({
  thead,
  tbody,
  setImagePopup,
  setOpenReasonPopup,
  openReasonPopup,
  setHoverId,
  hoverId,
  imagesData,
  setElementData,
  elementData,
  handleFeedback,
  handleUpdateFeedback,
  feedback,
  questions,
  checkData,
  setCheckData
}) => {
  const [showApproved, setShowApproved] = useState(null);

  const leftRear = imagesData?.filter(
    (item) => item.componentName === tireComponent.leftRear
  );
  const leftFront = imagesData?.filter(
    (item) => item.componentName === tireComponent.leftFront
  );
  const rightRear = imagesData?.filter(
    (item) => item.componentName === tireComponent.rightRear
  );
  const rightFront = imagesData?.filter(
    (item) => item.componentName === tireComponent.rightFront
  );

  const handleImageType = (name) => {
    let allImages = [];
    switch (name) {
      case tireComponent.leftRear:
        allImages = leftRear;
        break;
      case tireComponent.rightRear:
        allImages = rightRear;
        break;
      case tireComponent.leftFront:
        allImages = leftFront;
        break;
      case tireComponent.rightFront:
        allImages = rightFront;
        break;
      default:
        allImages = [];
    }
    return allImages;
  };

  return (
    <table className="w-full">
      <thead>
        <tr className="font-InterBold text-ft15-18 text-black-10">
          {thead.map((item, idx) => {
            const questionValues = questions[item?.componentName] || [];
            return (
              <td
                key={idx}
                className={` bg-darkGrey-30 py-3.5  pl-3.5 ${idx != 0 && "border-l border-grey-60"}`}
              >
                <div className="flex items-center gap-1">
                  <span
                    className={`whitespace-nowrap ${
                      item.name === "Mismatched" && item.status
                        ? " text-red-10"
                        : item.status && " font-InterMedium text-green-10"
                    }`}
                  >
                    {item.name}
                  </span>

                  {item.isMoreDetailsAvaialble && (
                    <div className="relative w-full">
                      <img
                        onClick={() => setShowApproved(idx)}
                        src={
                          item.isMoreDetailsAvaialble &&
                          item.name === "Approved"
                            ? "/images/green-check.svg"
                            : "/images/red-checkMark.svg"
                        }
                        alt=""
                        className="cursor-pointer"
                      />

                      {item.isMoreDetailsAvaialble && showApproved === idx && (
                        <CheckMarkpopup
                          checkValues={item?.moreSpecification}
                          componentValues={questionValues}
                          closeModal={() => setShowApproved(null)}
                          componentName={item.componentName}
                          reason={item?.reason}
                          componentStatus={item.name}
                          openReasonPopup={openReasonPopup}
                          setOpenReasonPopup={setOpenReasonPopup}
                          checkData={checkData}
                          setCheckData={setCheckData}
                          handleFeedback={handleFeedback}
                          handleUpdateFeedback={handleUpdateFeedback}
                          feedback={item?.feedback}
                          componentId={item?.componentId}
                        />
                      )}
                    </div>
                  )}
                </div>
              </td>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {tbody?.map((item, idx) => (
          <tr key={idx} className="border-t border-grey-30">
            <td className="w-25per py-3.5 pl-3.5 font-InterRegular text-ft10-18.15 text-grey-50">
              <div>{item.element}</div>
            </td>
            <td className="w-30per border-l border-grey-60 pl-3.5 font-InterRegular text-ft10-18.15 text-black-10">
              <div>
                {item.claimData?.toLowerCase() === "n"
                  ? "No"
                  : item?.claimData?.toLowerCase() === "y"
                    ? "Yes"
                    : item.claimData}
              </div>
            </td>
            <td
              onMouseEnter={() => {
                if (!openReasonPopup) setHoverId(item._id);
              }}
              onMouseLeave={() => {
                if (!openReasonPopup) setHoverId(null);
              }}
              className="w-30per border-l border-grey-60 px-3.5 font-InterRegular text-ft10-18.15 text-black-10"
            >
              <div className="flex items-center justify-between">
                <span>
                  {item.systemResults?.toLowerCase() === "n"
                    ? "No"
                    : item.systemResults?.toLowerCase() === "y"
                      ? "Yes"
                      : item.systemResults}
                </span>
                {item.feedback && (
                  <div className="relative flex gap-3">
                    {hoverId === item._id && item?.feedback && (
                      <img
                        onClick={() =>
                          setImagePopup((prev) => ({
                            ...prev,
                            popup: true,
                            allImages: handleImageType(item.componentName),
                            idx: 0
                          }))
                        }
                        src="/images/image-logo.svg"
                        alt=""
                        className="cursor-pointer"
                      />
                    )}
                    <img
                      onClick={() => {
                        setOpenReasonPopup(item._id);
                        setElementData((prev) => ({
                          ...prev,
                          elementName: item?.feedback?.elementName,
                          systemResult: item?.feedback?.systemResult,
                          claimData: item?.feedback?.claimData,
                          feedback: item.feedback.feedback,
                          comment: item?.feedback?.comment
                        }));
                      }}
                      src="/images/red-thumbs-down.svg"
                      alt=""
                      className="cursor-pointer"
                    />
                    {openReasonPopup === item._id && (
                      <FeedbackPopup
                        setState={() => setOpenReasonPopup(null)}
                        elementData={elementData}
                        setElementData={setElementData}
                        handleFeedback={handleFeedback}
                        handleUpdateFeedback={handleUpdateFeedback}
                        edit={true}
                      />
                    )}
                  </div>
                )}
                {hoverId === item._id && !item?.feedback && (
                  <div className="relative flex gap-3">
                    <img
                      onClick={() =>
                        setImagePopup((prev) => ({
                          ...prev,
                          popup: true,
                          allImages: handleImageType(item.componentName),
                          idx: 0
                        }))
                      }
                      src="/images/image-logo.svg"
                      alt=""
                      className="cursor-pointer"
                    />
                    <img
                      onClick={() => {
                        setOpenReasonPopup(item._id);
                        setElementData((prev) => ({
                          ...prev,
                          elementName:
                            item?.element + " " + item?.componentName,
                          systemResult: item.systemResults,
                          claimData: item.claimData
                        }));
                      }}
                      src="/images/dislike-black.svg"
                      alt=""
                      className="cursor-pointer"
                    />
                    {openReasonPopup === item._id && (
                      <FeedbackPopup
                        setState={() => setOpenReasonPopup(null)}
                        elementData={elementData}
                        setElementData={setElementData}
                        handleFeedback={handleFeedback}
                        handleUpdateFeedback={handleUpdateFeedback}
                        callUpdate={feedback ? true : false}
                      />
                    )}
                  </div>
                )}
              </div>
            </td>
            <td className="w-15per border-l border-grey-60 p-3.5">
              <div
                className={`${
                  item.result === "Mismatched"
                    ? " text-red-10"
                    : " text-green-10"
                } rounded-full font-InterMedium text-ft15-18`}
              >
                {item.result}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
