export default class Utils {
  /**
   * Get item from localStorage
   * @param {string} key - The key to identify the item in localStorage
   * @returns {any|null} Parsed JSON data from localStorage, or null if the key does not exist
   */
  static localStorageGetItem = (key) => {
    const data = localStorage.getItem(key);
    if (data) return JSON.parse(data);
    return null;
  };

  /**
   * Set item in localStorage
   * @param {string} key - The key to identify the item in localStorage
   * @param {any} data - Data to be stored in localStorage (will be JSON.stringify-ed)
   */
  static localStorageSetItem = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
  };

  /**
   * Remove item from localStorage
   * @param {string} key - The key to identify the item in localStorage
   */
  static localStorageRemoveItem = (key) => {
    localStorage.removeItem(key);
  };

  /**
   * This function is made to handle success and error callback!
   * @param {Promise} promise - The promise to be handled
   * @returns {Promise<Array>} A promise that resolves to an array with either [null, data] on success or [err] on failure
   */
  static async parseResponse(promise) {
    return promise.then((data) => [null, data]).catch((err) => [err]);
  }

  /**
   * Generate a GUID (Globally Unique Identifier)
   * @returns {string} A string representing a GUID
   */
  static guid() {
    const s4 = () =>
      Math.floor(Math.random() * 65536)
        .toString(16)
        .padStart(4, "0");

    return (
      s4() +
      s4() +
      "-" +
      s4() +
      "-" +
      "4" +
      s4().substring(1) +
      "-" +
      s4() +
      "-" +
      s4() +
      s4() +
      s4()
    );
  }
  static addDays(date, days) {
    return new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
  }

  static storeToLocalStorage(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  static getDataFromLocalStorage(key) {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  }

  static formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  static formatDateYear = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  static formatText(text) {
    return text
      ?.split(" ")
      ?.map(
        (word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase()
      )
      ?.join(" ");
  }

  static camelToTitleCase(str) {
    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/^./, (match) => match.toUpperCase());
  }

  static getDateRange = (label) => {
    const now = new Date();
    switch (label) {
      case "Last 14 days":
        return {
          startDate: Utils.formatDateYear(
            new Date(now - 13 * 24 * 60 * 60 * 1000)
          ),
          endDate: Utils.formatDateYear(now)
        };
      case "Previous month": {
        const firstDayOfMonth = new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          1
        );
        const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 0);
        return {
          startDate: Utils.formatDateYear(firstDayOfMonth),
          endDate: Utils.formatDateYear(lastDayOfMonth)
        };
      }

      default:
        return null;
    }
  };

  static getDateRangeProcessed = (label) => {
    const now = new Date();

    const startOfDayUTC = (date) => {
      return Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        0,
        0,
        0,
        0
      );
    };

    const endOfDayUTC = (date) => {
      return Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        23,
        59,
        59,
        999
      );
    };

    switch (label) {
      case "Last 7 days":
        return {
          startDate: startOfDayUTC(
            new Date(now.getTime() - 6 * 24 * 60 * 60 * 1000)
          ),
          endDate: endOfDayUTC(now)
        };
      case "Last 14 days":
        return {
          startDate: startOfDayUTC(
            new Date(now.getTime() - 13 * 24 * 60 * 60 * 1000)
          ),
          endDate: endOfDayUTC(now)
        };
      case "Previous month": {
        const firstDayOfMonth = new Date(
          now.getUTCFullYear(),
          now.getUTCMonth() - 1,
          1
        );
        const lastDayOfMonth = new Date(
          now.getUTCFullYear(),
          now.getUTCMonth(),
          0
        );
        return {
          startDate: startOfDayUTC(firstDayOfMonth),
          endDate: endOfDayUTC(lastDayOfMonth)
        };
      }
      default:
        return null;
    }
  };
}
