import React, { useState } from "react";
import Utils from "../../utility";

const ImageZoom = ({ openImage, setImagePopup, data }) => {
  const [scale, setScale] = useState(1);
  const [index, setIndex] = useState(openImage?.idx || 0);

  const zoomIn = () => setScale(scale + 0.1);
  const zoomOut = () => setScale(scale > 1 ? scale - 0.1 : scale);

  const closePreviewModal = () => {
    setScale(1);
    setImagePopup((prev) => ({ ...prev, popup: false }));
  };

  const componentName = openImage?.allImages[index]?.componentName;
  const componentData = data[componentName] || [];

  return (
    <div className="fixed right-0  top-0 z-50  flex size-full bg-black-0">
      <div className="flex h-full w-20 flex-col justify-between pb-12 pl-6 pt-6">
        <div className="cursor-pointer">
          <img
            className="w-4 rounded object-cover"
            src="/images/white-cross-icon.svg"
            alt="white-cross-icon"
            onClick={closePreviewModal}
          />
        </div>
        <div className="flex flex-col gap-7">
          <div className="cursor-pointer" onClick={zoomIn}>
            <img
              className="w-4 rounded object-cover"
              src="/images/white-plus-icon.svg"
              alt="white-cross-icon"
            />
          </div>
          <div className="cursor-pointer" onClick={zoomOut}>
            <img
              className="w-4 rounded object-cover"
              src="/images/white-minus-icon.svg"
              alt="white-cross-icon"
            />
          </div>
        </div>
      </div>
      <div className="flex size-full">
        <div className="flex h-full w-80per flex-col items-center justify-between gap-8 overflow-y-auto pb-12">
          <div className="relative flex h-85per w-full flex-col items-center gap-8">
            <div className="relative h-full w-auto min-w-50per overflow-auto">
              <div className="flex size-full items-center justify-center">
                {openImage?.allImages[index]?.image
                  ?.toLowerCase()
                  ?.includes(".pdf") ? (
                  <div className="size-full">
                    <iframe
                      src={openImage?.allImages[index]?.image}
                      width="100%"
                      height="100%"
                    />
                  </div>
                ) : openImage?.allImages[index]?.image ? (
                  <img
                    src={openImage?.allImages[index]?.image}
                    alt="Preview"
                    style={{
                      transform: `scale(${scale})`,
                      transformOrigin: "top left"
                    }}
                    className="size-full transition-transform duration-300"
                  />
                ) : (
                  <div className="flex size-full flex-col  items-center justify-center gap-3 bg-grey-90 text-center">
                    <span className="w-full font-InterRegular text-ft18-21 text-grey-20">
                      No Images attached
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="absolute bottom-5 flex w-full items-center justify-center gap-3.5 bg-transparent">
              <div
                onClick={() => {
                  if (index > 0) setIndex(index - 1);
                }}
                className="flex size-10 cursor-pointer items-center justify-center rounded-full bg-black-0 bg-opacity-[.8]"
              >
                <img src="/images/left-white-arrow.svg" alt="" />
              </div>
              <div
                onClick={() => {
                  if (openImage?.allImages?.length > index + 1)
                    setIndex(index + 1);
                }}
                className="flex size-10 cursor-pointer items-center justify-center rounded-full bg-black-0 bg-opacity-[.8]"
              >
                <img src="/images/right-white-arrow.svg" alt="" />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap gap-2">
            {openImage?.allImages?.map((item, idx) => {
              return (
                <div
                  onClick={() => setIndex(idx)}
                  key={idx}
                  className="size-24 cursor-pointer rounded-[10px]"
                >
                  {item?.image?.toLowerCase()?.includes(".pdf") ? (
                    <img
                      src="/images/document.svg"
                      alt=""
                      className="size-full rounded-[10px]"
                    />
                  ) : item.image ? (
                    <img
                      src={item.image}
                      alt=""
                      className="size-full rounded-[10px]"
                    />
                  ) : (
                    <div className="flex size-full flex-col items-center justify-center gap-3 rounded-lg bg-grey-90 text-center">
                      <img src="/images/image-logo-light.svg" alt="logo" />
                      <span className="w-20 font-InterRegular text-ft12-16 text-grey-20">
                        No Images attached
                      </span>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="w-20per bg-white p-5">
          <div className="font-InterMedium text-ft18-21 text-black-10">
            {Utils.formatText(componentName)}
          </div>
          <div className="flex gap-1 pt-5">
            <span className="rounded border border-grey-70 bg-grey-80 p-1 font-PoppinsRegular text-ft12-16 text-black-10">
              {Utils.formatText(openImage?.allImages[index]?.type)}
            </span>
          </div>

          {componentData?.length > 0 && (
            <div className="mt-5 flex flex-col gap-3 rounded-md bg-grey-110 p-4">
              {componentData?.map((item, idx) => {
                if (item?.questionText === "") return;
                return (
                  <div key={idx} className="flex justify-between">
                    <span className="w-4/5 font-InterRegular text-ft14-17 text-black-10">
                      {item.questionText}
                    </span>
                    <span className="font-InterMedium text-ft14-17 text-black-10">
                      {item?.answer === "y"
                        ? "Yes"
                        : item?.answer === "n"
                          ? "No"
                          : Utils.formatText(item.answer)}
                    </span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageZoom;
