import React from "react";
const Pagination = ({
  currentPage,
  handlePageChange = () => {},
  totalPages,
  handleLastPage = () => {},
  handleFirstPage = () => {}
}) => {
  return (
    <>
      <div className="mt-5 flex w-full flex-row justify-end gap-5 font-InterMedium text-black-10">
        <button
          disabled={currentPage === 1}
          onClick={() => {
            handleFirstPage();
          }}
          className={`${
            currentPage === 1 ? "text-grey-20 " : "text-black-10"
          } flex cursor-pointer text-ft14-17`}
        >
          First
        </button>
        <button className=" rounded-md bg-white">
          <img
            src="/images/Left.svg"
            alt=""
            onClick={() => {
              handlePageChange(currentPage - 1);
            }}
            className={"flex h-3 text-blue-10"}
          />
        </button>
        <span className="text-ft14-17">
          Page {currentPage} of {totalPages}
        </span>
        <button>
          <img
            src="/images/Right.svg"
            onClick={() => {
              handlePageChange(currentPage + 1);
            }}
            className="flex h-5 text-blue-10"
          />
        </button>
        <button
          disabled={currentPage === totalPages}
          onClick={() => {
            handleLastPage();
          }}
          className={`${
            currentPage === totalPages ? "text-grey-20 " : "text-black-10"
          } flex cursor-pointer text-ft14-17`}
        >
          Last
        </button>
      </div>
    </>
  );
};
export default Pagination;
