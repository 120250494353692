import React, { useState } from "react";
import ClickOutside from "./clickOutside";

const Dropdown = ({
  width,
  text,
  value,
  list = [],
  handleSelect = () => {},
  setOpenCalendar = () => {},
  setCurrentPage = () => {},
  startDate,
  endDate
}) => {
  const [open, setOpen] = useState(false);
  const formatDate = (dateString) => {
    if (!dateString) return;
    return dateString?.replace(/-/g, "/");
  };

  return (
    <ClickOutside
      isOpen={open}
      onClickOutside={() => {
        setOpen(false);
      }}
    >
      <div className="relative">
        <div
          onClick={() => {
            setOpen(!open);
            setOpenCalendar(false);
          }}
          className={`flex items-center justify-between gap-2 rounded-lg border border-grey-10 p-3  ${width} h-10 cursor-pointer bg-white`}
        >
          <span className="font-InterRegular text-ft14-17 text-grey-120">
            {text} :
          </span>
          <div className="flex items-center gap-2">
            <span className="font-InterMedium text-ft14-17 text-black-10">
              {value === "Custom" && startDate && endDate
                ? formatDate(startDate) + "-" + formatDate(endDate)
                : value}
            </span>
            <img src="/images/down-icon.svg" alt="" />
          </div>
        </div>
        {open && (
          <div className="absolute z-30 flex h-max w-full flex-col rounded-lg border border-grey-10 bg-white">
            {list?.map((item, idx) => (
              <span
                key={idx}
                className="cursor-pointer px-4 py-2 hover:bg-grey-40"
                onClick={() => {
                  handleSelect(item);
                  setCurrentPage(1);
                  if (item !== "Custom") setOpen(false);
                }}
              >
                {item}
              </span>
            ))}
          </div>
        )}
      </div>
    </ClickOutside>
  );
};

export default Dropdown;
