import Cookies from "universal-cookie";
import utility from "../utility";

const cookies = new Cookies();

function setDataInCookies(data, key, expires = 30) {
  let expireDate;
  if (expires.toString().length === 10) {
    expireDate = new Date(expires * 1000);
  } else {
    expireDate = utility.addDays(new Date(), expires);
  }
  cookies.set(key, JSON.stringify(data), { path: "/", expires: expireDate });
}

function getDataFromCookies(key) {
  return cookies.get(key);
}

function removeDataFromCookies(key) {
  cookies.remove(key, { path: "/" });
}

export const sessionManager = {
  setDataInCookies,
  getDataFromCookies,
  removeDataFromCookies
};
