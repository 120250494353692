import React from "react";
import { Navigate } from "react-router-dom";
import Header from "./common/header";
import { keyConstants } from "./constants";
import { sessionManager } from "./managers/sessionManager";

const PrivateRoute = ({ element: Element, bg = "bg-white" }) => {
  const isAuthenticated = () => {
    const getToken = sessionManager.getDataFromCookies(keyConstants.TOKEN);
    return !!getToken;
  };

  return isAuthenticated() ? (
    <div className={`flex w-full flex-col ${bg} h-screen overflow-y-auto`}>
      <Header />
      <div className="flex w-full justify-center">
        <div className="h-full w-80per py-5">
          <Element />
        </div>
      </div>
    </div>
  ) : (
    <Navigate to="/" replace />
  );
};

export default PrivateRoute;
