import React, { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { sessionManager } from "../managers/sessionManager";
import { keyConstants } from "../constants";
import ClickOutside from "./components/clickOutside";
import { useSelector } from "react-redux";

const Header = () => {
  const { oktaAuth } = useOktaAuth();
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.user);

  const getInitials = (name) => {
    if (!name) return "";
    const parts = name.split(" ");
    if (parts.length === 1) {
      return parts[0][0].toUpperCase();
    }
    const firstInitial = parts[0][0].toUpperCase();
    const lastInitial = parts[parts.length - 1][0].toUpperCase();
    return `${firstInitial}${lastInitial}`;
  };

  return (
    <div className="flex h-14 w-full items-center justify-between bg-headerGradient px-5 py-4">
      <div className="h-8">
        <img src="/images/safe-guard-logo-white.svg" alt="" />
      </div>
      <ClickOutside
        isOpen={open}
        onClickOutside={() => {
          setOpen(false);
        }}
      >
        <div className="relative">
          <div
            onClick={() => setOpen((prev) => !prev)}
            className="flex size-10 cursor-pointer items-center justify-center rounded-full bg-grey-10 p-3"
          >
            <span className="font-InterMedium text-ft14-17 text-black-10">
              {getInitials(user?.name)}
            </span>
          </div>

          {open && (
            <div className="absolute right-0 top-11 cursor-pointer rounded-sm border border-grey-10 bg-white px-4 py-1 drop-shadow-sm">
              <span
                onClick={() => {
                  oktaAuth.signOut();
                  sessionManager.removeDataFromCookies(keyConstants.TOKEN);
                  localStorage.clear();
                }}
                className="font-InterMedium text-ft14-17 text-black-10"
              >
                Logout
              </span>
            </div>
          )}
        </div>
      </ClickOutside>
    </div>
  );
};

export default Header;
