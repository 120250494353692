import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ClaimListComponent from "./modules/claimList";
import PrivateRoute from "./privateRoute";
import ClaimDetailsComponent from "./modules/claimDetails";
import OktaLogin from "./modules/login";
import ClaimReportComponent from "./modules/claimReport";
import CommercialUsageComponent from "./modules/commercialUsage";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<OktaLogin />} />
      <Route
        path="/claim-list"
        element={<PrivateRoute element={ClaimListComponent} />}
      />
      <Route
        path="/claim/:claimNumber/:version/"
        element={
          <PrivateRoute element={ClaimDetailsComponent} bg={"bg-grey-40"} />
        }
      />
      <Route
        path="/claim-report"
        element={<PrivateRoute element={ClaimReportComponent} />}
      />
      <Route
        path="/commercial-report"
        element={<PrivateRoute element={CommercialUsageComponent} />}
      />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default Router;
