import React, { useState } from "react";
import Utils from "../../utility";
import FeedbackPopup from "../../common/components/popup/feedbackPopup";

const CheckMarkpopup = ({
  checkValues,
  componentValues,
  closeModal,
  componentName,
  reason,
  componentStatus,
  openReasonPopup,
  setOpenReasonPopup,
  checkData,
  setCheckData,
  handleFeedback,
  handleUpdateFeedback,
  feedback,
  componentId
}) => {
  const [checks, setChecks] = useState(true);

  const mandatoryChecksStatus = (checkValues) => {
    const sameTireCheck = checkValues?.find(
      (check) =>
        check?.checkName?.toLowerCase() === "sametire" &&
        check?.checkValue?.toLowerCase() === "yes"
    );
    const otherChecksAreNo = checkValues?.every((check) => {
      if (check?.checkName?.toLowerCase() !== "sametire") {
        return check?.checkValue?.toLowerCase() === "no";
      }
      return true;
    });
    if (sameTireCheck && otherChecksAreNo) {
      return "Approved";
    } else {
      return "Denied";
    }
  };

  const mandatoryCheck = mandatoryChecksStatus(checkValues);

  return (
    <div className="fixed right-0 top-0 z-50 flex size-full items-center justify-center bg-black-0 bg-opacity-50">
      <div className="max-h-70per min-h-80 w-30per overflow-y-auto rounded-lg border border-grey-30 bg-white p-4 shadow-popupShadow">
        <div className="flex cursor-pointer justify-between">
          <div className="flex gap-3">
            <span className="font-InterSemiBold text-ft18-21 text-black-10">
              {Utils.formatText(componentName)}
            </span>
            <div
              className={`flex h-6 items-center justify-center rounded-[4px] ${componentStatus === "Approved" ? "bg-green-10" : "bg-red-10"}  px-2 py-1`}
            >
              <span className="font-InterSemiBold text-ft15-18  text-white">
                {componentStatus}
              </span>
            </div>
          </div>
          <div onClick={closeModal}>
            <img
              className="w-4 rounded object-cover"
              src="/images/cross.svg"
              alt="cross-icon"
            />
          </div>
        </div>
        <div className="mt-9 flex w-full">
          <div
            onClick={() => setChecks(true)}
            className={`flex h-10 w-1/2 cursor-pointer items-center justify-center rounded-l-md border ${checks ? "border-black-0 text-black-10" : "border-grey-30 text-black-20"}`}
          >
            <span className=" font-InterMedium">Checks</span>
          </div>
          <div
            onClick={() => setChecks(false)}
            className={`flex h-10 w-1/2 cursor-pointer items-center justify-center rounded-r-md border ${!checks ? "border-black-0 text-black-10" : "border-grey-30 text-black-20"}`}
          >
            <span className=" font-InterMedium">Description</span>
          </div>
        </div>
        {checks ? (
          <div>
            <div className="mt-6">
              <div className="flex w-full justify-between">
                <span className="font-InterRegular text-ft14-17 text-black-20">
                  Mandatory Checks
                </span>
                <span
                  className={`font-InterRegular text-ft14-17 ${mandatoryCheck === "Approved" ? "text-green-10" : "text-red-10"} `}
                >
                  {mandatoryCheck}
                </span>
              </div>

              <div className="flex flex-col gap-3 rounded-md bg-grey-110 p-4">
                {checkValues?.map((item, idx) => {
                  const feedbackPresent = feedback?.find(
                    (value) => value.checkId === item?._id
                  );
                  return (
                    <div key={idx} className="flex justify-between">
                      <span className="font-InterRegular text-ft14-17 text-black-10">
                        {item.checkName
                          .replace(/([A-Z])/g, " $1")
                          .trim()
                          .replace(/^./, (str) => str.toUpperCase())}
                      </span>
                      <div className="flex gap-2">
                        <span className="font-InterMedium text-ft14-17 text-black-10">
                          {item?.checkValue === "y"
                            ? "Yes"
                            : item?.checkValue === "n"
                              ? "No"
                              : Utils.formatText(item.checkValue)}
                        </span>
                        <div className="relative cursor-pointer">
                          {feedbackPresent ? (
                            <img
                              onClick={() => {
                                setOpenReasonPopup(item?._id);
                                setOpenReasonPopup(item?._id);
                                setCheckData((prev) => ({
                                  ...prev,
                                  checkId: item?._id,
                                  checkName: item?.checkName,
                                  feedback: feedbackPresent?.feedback,
                                  comment: feedbackPresent?.comment,
                                  componentName: componentName,
                                  componentId: componentId,
                                  systemResult: item?.checkValue
                                }));
                              }}
                              src="/images/red-thumbs-down.svg"
                              alt=""
                              className="cursor-pointer"
                            />
                          ) : (
                            <img
                              onClick={() => {
                                setOpenReasonPopup(item?._id);
                                setOpenReasonPopup(item?._id);
                                setCheckData((prev) => ({
                                  ...prev,
                                  checkId: item?._id,
                                  checkName: item?.checkName,
                                  feedback: feedbackPresent?.feedback,
                                  comment: feedbackPresent?.comment,
                                  componentName: componentName,
                                  componentId: componentId,
                                  systemResult: item?.checkValue
                                }));
                              }}
                              src="/images/dislike-black.svg"
                              alt=""
                              className="cursor-pointer"
                            />
                          )}
                          {item?._id === openReasonPopup && (
                            <FeedbackPopup
                              align="right-0"
                              elementData={checkData}
                              setElementData={setCheckData}
                              handleFeedback={handleFeedback}
                              setState={() => setOpenReasonPopup(null)}
                              handleUpdateFeedback={handleUpdateFeedback}
                              edit={feedbackPresent ? true : false}
                              callUpdate={feedback ? true : false}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {mandatoryCheck === "Approved" && (
              <div className="mt-6">
                <div className="flex w-full justify-between">
                  <span className="font-InterRegular text-ft14-17 text-black-20">
                    Additional Questions
                  </span>
                  <span
                    className={`font-InterRegular text-ft14-17 ${componentStatus === "Approved" ? "text-green-10" : "text-red-10"} `}
                  >
                    {componentStatus}
                  </span>
                </div>
                <div className="flex flex-col gap-3 rounded-md bg-grey-110 p-4">
                  {componentValues?.map((item, idx) => {
                    if (item?.questionText === "") return;
                    const feedbackPresent = feedback?.find(
                      (value) => value.checkId === item?._id
                    );
                    return (
                      <div key={idx} className="flex justify-between">
                        <span className="w-3/5 font-InterRegular text-ft14-17 text-black-10">
                          {item.questionText}
                        </span>
                        <div className="flex gap-2">
                          <span className="font-InterMedium text-ft14-17 text-black-10">
                            {item?.answer === "y"
                              ? "Yes"
                              : item?.answer === "n"
                                ? "No"
                                : Utils.formatText(item.answer)}
                          </span>
                          <div className="relative cursor-pointer">
                            {feedbackPresent ? (
                              <img
                                onClick={() => {
                                  setOpenReasonPopup(item?._id);
                                  setCheckData((prev) => ({
                                    ...prev,
                                    checkId: item?._id,
                                    checkName: item?.questionText,
                                    feedback: feedbackPresent?.feedback,
                                    comment: feedbackPresent?.comment,
                                    componentName: componentName,
                                    componentId: componentId,
                                    systemResult: item?.checkValue
                                  }));
                                }}
                                src="/images/red-thumbs-down.svg"
                                alt=""
                                className="cursor-pointer"
                              />
                            ) : (
                              <img
                                onClick={() => {
                                  setOpenReasonPopup(item?._id);
                                  setCheckData((prev) => ({
                                    ...prev,
                                    checkId: item?._id,
                                    checkName: item?.questionText,
                                    feedback: feedbackPresent?.feedback,
                                    comment: feedbackPresent?.comment,
                                    componentName: componentName,
                                    componentId: componentId,
                                    systemResult: item?.checkValue
                                  }));
                                }}
                                src="/images/dislike-black.svg"
                                alt=""
                                className="cursor-pointer"
                              />
                            )}
                            {item?._id === openReasonPopup && (
                              <FeedbackPopup
                                align="right-0"
                                elementData={checkData}
                                setElementData={setCheckData}
                                setState={() => setOpenReasonPopup(null)}
                                handleFeedback={handleFeedback}
                                handleUpdateFeedback={handleUpdateFeedback}
                                edit={feedbackPresent ? true : false}
                                callUpdate={feedback ? true : false}
                                topAlign="bottom-4"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="mt-6 h-full rounded-md bg-grey-110 p-4 font-InterMedium text-black-10">
            {reason}
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckMarkpopup;
