import React from "react";
import ReactDOM from "react-dom/client";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/custom.css";
import reportWebVitals from "./reportWebVitals";
import Routes from "./routes";
import history from "./managers/history";
import { store } from "./store";
import { Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { keyConstants } from "./constants";

const WebApp = () => {
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  const config = {
    issuer: `${process.env.REACT_APP_BASEURL_OKTA_APP}/oauth2/default`,
    clientId: process.env.REACT_APP_CLIENTID,
    redirectUri: `${window.location.protocol}//${window.location.host}/claim-list`,
    scopes: keyConstants.SCOPES
  };
  const oktaAuth = new OktaAuth(config);

  return (
    <HistoryRouter history={history}>
      <Provider store={store}>
        <Security restoreOriginalUri={restoreOriginalUri} oktaAuth={oktaAuth}>
          <Routes />
          <ToastContainer />
        </Security>
      </Provider>
    </HistoryRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<WebApp />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
