import React from "react";
import OktaSignInWidget from "./oktaWidget";
import { useDispatch } from "react-redux";
import { updateUser } from "../../slices/userSlice";

function OktaLogin() {
  const dispatch = useDispatch();
  const onSuccess = async (res) => {
    const { tokens } = res;
    const { idToken, accessToken } = tokens;
    const userInfo = idToken.claims;
    const userData = {
      name: userInfo?.name || "okta_user",
      email: userInfo.email,
      userId: userInfo?.sub,
      accessToken: accessToken?.accessToken,
      profile: userInfo?.profile
    };

    try {
      dispatch(updateUser(userData));
    } catch (error) {
      console.error("Error saving user:", error);
    }
  };

  const onError = (err) => {
    console.error("Error:", err);
  };

  return (
    <div>
      <OktaSignInWidget onSuccess={onSuccess} onError={onError} />
    </div>
  );
}

export default OktaLogin;
