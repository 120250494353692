import React, { useEffect } from "react";
import FeedbackPopup from "../../common/components/popup/feedbackPopup";
import { BiSolidDislike } from "react-icons/bi";
import Utils from "../../utility";

const RightPanel = ({
  setImagePopup,
  imagesData,
  openReasonPopup,
  setClaimData,
  claimData,
  handleFeedback,
  handleUpdateFeedback,
  setOpenReasonPopup,
  data
}) => {
  useEffect(() => {
    if (data?.feedback?.feedback)
      setClaimData((prev) => ({
        ...prev,
        comment: data?.feedback?.comment,
        feedback: data?.feedback?.feedback
      }));
    //eslint-disable-next-line
  }, []);
  return (
    <div className="flex flex-col gap-4">
      <div
        className={`flex w-full items-center justify-between rounded-lg ${data?.comparisonResult === "Matched" ? "bg-green-10" : "bg-red-10"} px-4.5 py-3`}
      >
        <div className="flex gap-1">
          <img
            src={`${data?.comparisonResult === "Matched" ? "/images/approve.svg" : "/images/white-cross.svg"}`}
            alt=""
          />
          <span className="font-InterSemiBold text-ft16-24 text-white">
            {data?.comparisonResult === "Matched" ? "Matched" : "Mismatched"}
          </span>
        </div>
        <div className="relative items-center">
          {!data?.feedback?.feedback ? (
            <img
              onClick={() => setOpenReasonPopup("CLAIM_FEEDBACK")}
              src="/images/dislike.svg"
              alt=""
              className="cursor-pointer"
            />
          ) : (
            <BiSolidDislike
              onClick={() => setOpenReasonPopup("CLAIM_FEEDBACK")}
              color="white"
              className="size-[17px] cursor-pointer"
            />
          )}
          {openReasonPopup === "CLAIM_FEEDBACK" && (
            <FeedbackPopup
              align="right-0"
              setState={setOpenReasonPopup}
              elementData={claimData}
              handleFeedback={handleFeedback}
              setElementData={setClaimData}
              handleUpdateFeedback={handleUpdateFeedback}
              edit={data?.feedback?.feedback ? true : false}
              callUpdate={data?.feedback !== null ? true : false}
            />
          )}
        </div>
      </div>
      <div className="flex w-full justify-between rounded-lg bg-white px-4 py-5">
        <span className="font-InterMedium text-ft15-18 text-grey-50">
          Human Adjuster Decision
        </span>
        <span className="font-InterSemiBold text-ft15-18 text-black-0">
          {Utils.formatText(data?.sgStatus)}
        </span>
      </div>
      <div className="flex w-full justify-between rounded-lg bg-white px-4 py-5">
        <span className="font-InterMedium text-ft15-18 text-grey-50">
          System Decision
        </span>
        <span className="font-InterSemiBold text-ft15-18 text-black-0">
          {Utils.formatText(data?.claimStatus)}
        </span>
      </div>
      <div className="rounded-lg bg-white p-4">
        <h1 className="font-InterSemiBold text-ft20-28 text-black-10">
          All Images
        </h1>
        <div className="grid size-full grid-cols-2 gap-5 pt-4.5">
          {imagesData.map((item, idx) => (
            <div key={idx} className="flex w-full flex-col gap-2">
              <div
                onClick={() => {
                  if (item.image) {
                    setImagePopup((prev) => ({
                      ...prev,
                      popup: true,
                      allImages: imagesData,
                      idx: idx
                    }));
                  }
                }}
                className="relative w-full cursor-pointer"
              >
                {item.image?.toLowerCase()?.includes(".pdf") ? (
                  <img
                    src="/images/document.svg"
                    alt=""
                    className="h-32 w-full rounded-lg"
                  />
                ) : item.image ? (
                  <img
                    src={item.image}
                    alt=""
                    className="h-32 w-full rounded-lg"
                  />
                ) : (
                  <div className="flex h-32 w-full flex-col items-center justify-center gap-3 rounded-lg bg-grey-90 text-center">
                    <img src="/images/image-logo-light.svg" alt="logo" />
                    <span className="w-20 font-InterRegular text-ft12-16 text-grey-20">
                      No Images attached
                    </span>
                  </div>
                )}
                <div className="absolute bottom-2 right-1">
                  <img src="/images/zoom.svg" alt="" />
                </div>
              </div>
              <span className="w-max rounded-[4px] border border-grey-70 bg-grey-80 px-2 py-1 font-InterRegular text-ft12-16 text-black-0">
                {Utils.formatText(item.componentName)}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RightPanel;
