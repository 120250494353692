import { createSlice } from "@reduxjs/toolkit";
import { sessionManager } from "../managers/sessionManager";
import { keyConstants } from "../constants";
import Utils from "../utility";

const getToken = () => sessionManager.getDataFromCookies(keyConstants.TOKEN);

const token = getToken();

const getUserData = () => {
  if (token) return Utils.getDataFromLocalStorage(keyConstants.USER);
  localStorage.clear();
  return null;
};

const storeData = (data) => {
  Utils.storeToLocalStorage(keyConstants.USER, data);
};
const data = getUserData() || {};
const initialState = {
  userId: data?.userId || "",
  email: data.email || "",
  name: data.name || "",
  accessToken: data.accessToken || "",
  profile: data.token || ""
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      const updatedState = {
        ...state,
        ...action.payload
      };
      storeData(updatedState);
      return updatedState;
    }
  }
});

export const { updateUser } = userSlice.actions;

export default userSlice.reducer;
